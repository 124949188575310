import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"
// modules
import auth from './modules/auth';
import gigaoffice from './modules/gigaoffice';

Vue.use(Vuex);

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        auth,
        gigaoffice
    },
    strict: process.env.NODE_ENV !== 'production'
})