import HttpRequest from './base/http_request'

class AuthService extends HttpRequest {
    //로그인
    login(payload) {
        return this.post(`${process.env.VUE_APP_API_ALIAS}/auth/authorize`, payload)
    }
    //로그아웃
    logout(payload) {
        return this.post(`${process.env.VUE_APP_API_ALIAS}/auth/logout?aa=`+sessionStorage.getItem('userId')+`&ip_addr=`+sessionStorage.getItem('ip'), payload)
    }
    //토큰으로 유저정보 받아오기
    getUserInfoByToken(token_id) {
        return this.post(`${process.env.VUE_APP_API_ALIAS}/auth/tokenid-info`, token_id)
    }
    //토큰으로 청약정보 받아오기
    getOwnerContactInfoByTokenId(payload) {
        return this.post(`${process.env.VUE_APP_API_ALIAS}/auth/contact-info`, payload)
      }
     //1.고객번호 조회
    getUserInfo(payload) {
        return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/user-info`, payload)
    }
    //client ip조회
    getIpAddress(payload) {
        return this.post(`${process.env.VUE_APP_API_ALIAS}/com/ip-addr`, payload)
    }
    getAuthRsa(payload){
        return this.post(`${process.env.VUE_APP_API_ALIAS}/auth/rsa`, payload)
    }
}

export default AuthService
