import * as axios from "axios";
import router from "@/router";
import store from "@/store/store";

// import recap_index from '@/../public/recap_index.js'
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000 //timeout 15초
});

axiosInstance.interceptors.request.use(
  function(config) {
    //로그인시 입력ID
    if (config.url === "/gigaoffice/api/v1/auth/authorize") {
      config.headers["user_id"] = config.data.user_id;
      config.headers["token_id"] = "";
      //토큰으로 정보받아올때
    } else if (config.url === "/gigaoffice/api/v1/auth/tokenid-info") {
      config.headers["user_id"] = config.data.user_id;
      config.headers["token_id"] = config.data.token_id;
      //기타
    } else {
      config.headers["token_id"] = sessionStorage.getItem("accessToken");
      config.headers["user_id"] = sessionStorage.getItem("userId");
    }
    //공통
    config.headers["ip_addr"] = sessionStorage.getItem("ip");

    let token = sessionStorage.getItem("accessToken");
    if (token) {
      config.headers["x-access-token"] = token;
    }
    let accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    store.commit("START_SPINNER");
    return config;
  },
  function(error) {
    router.replace("/");
    store.commit("END_SPINNER");
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function(response) {
    if (response.data.code == "301" || response.data.code == "302") {
      alert(response.data.message);
      // router.replace('/gigaoffice/goMain000')
      let param = {};
      store
        .dispatch("auth/logout", param)
        .then(res => {
          console.log(res);
        })
        .catch(error => {
          console.error(error);
        });
      // return false
      store.commit("LOGOUT");
    } else {
      store.commit("END_SPINNER");
      return response;
    }
  },
  function(error) {
    // console.log(error)
    if (error.response.status === 403 || error.response.status === 401) {
      store.commit("END_SPINNER");
      router.replace("/");
    } else {
      store.commit("END_SPINNER");
      return Promise.reject(error);
    }
  }
);

class HttpRequest {
  constructor() {
    this.axios = axios;
  }

  setHeader(header) {
    axiosInstance.defaults.headers.common[header.key] = header.value;
    axiosInstance.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
    // axiosInstance.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
  }

  setAuthorizationHeader(accessToken) {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;
  }

  get(methodName1, data1) {
    return axiosInstance.get(methodName1, {
      params: data1
    });
  }

  post(methodName2, data2) {
    //로그인인 경우를 제외하고 token_id와 user_id를 통신시에 백엔드로 보냄
    if (methodName2 != "/gigaoffice/api/v1/auth/authorize" && methodName2 != "/gigaoffice/api/v1/auth/tokenid-info") {
      if (data2 != null && data2 != "undefined") {
        data2.token_id = sessionStorage.getItem("accessToken");
        data2.user_id = sessionStorage.getItem("userId");
        data2.ip_addr = sessionStorage.getItem("ip");
      }
    }
    return axiosInstance.post(methodName2, data2);
  }

  put(methodName3, data3) {
    return axiosInstance.put(methodName3, data3);
  }

  delete(methodName4, id) {
    return axiosInstance.delete(methodName4, { params: { id: id } });
  }

  request(type, url, data) {
    let promise = null;
    switch (type) {
      case "GET":
        promise = axios.get(url, { params: data });
        break;
      case "POST":
        promise = axios.post(url, data);
        break;
      case "PUT":
        promise = axios.put(url, data);
        break;
      case "DELETE":
        promise = axios.delete(url, data);
        break;
      default:
        promise = axios.get(url, { params: data });
        break;
    }
    return promise;
  }
}

export default HttpRequest;
