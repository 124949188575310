<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP  
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
	<div id="app">
    <spinner-bar v-if="this.$store.state.LoadingStatus"></spinner-bar>
		<!-- <router-view @setAppClasses="setAppClasses" /> -->
    <router-view :key="$route.fullPath"></router-view>
	</div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'
import common from './assets/js/common'
import SpinnerBar from './views/gigaoffice/common/SpinnerBar.vue'
export default {
  data() {
    return {
      vueAppClasses: [],
    }
  },
  watch: {
    },
  components: {
    SpinnerBar
  },
  methods: {
    },
  mounted() {
  },
  async created() {

  },
  destroyed() {
  },
}

</script>
<style>
@import './assets/css/common.css';
@import './assets/css/style.css';
@import './assets/css/font.css';
@import './assets/css/reset.css';
</style>
