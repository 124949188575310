/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import Vue from 'vue'
import App from './App.vue'
import vueCompositionApi from '@vue/composition-api'
// axios
import axios from './axios'
Vue.prototype.$http = axios

// 20230627 RECAPTCHA 예외
import { VueReCaptcha } from 'vue-recaptcha-v3'
// 20230627 RECAPTCHA 예외
// LoadScript
import LoadScript from 'vue-plugin-load-script'

// Vue Router
import router from './router'

// v-mask
import VueMask from 'v-mask'

// Vuex Store
import store from './store/store'

import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
// require('./assets/js/index.js')
import VueSmoothScroll from 'vue2-smooth-scroll'
import vClickOutside from 'v-click-outside'
// require('./assets/js/jquery11.1.min.js')
// import SmoothScrollbar from 'vue-smooth-scrollbar'
// Vue.use(vueCompositionApi)

Vue.use(vClickOutside)

Vue.use(VueSmoothScroll,
  {duration: 400,
  updateHistory: false})
// Vue.use(SmoothScrollbar)
// Vue.use(VueReCaptcha, {siteKey: '6Ld30yMiAAAAAOX-YQmsjzC1Ttda8evCVRbg_keb'}) //기존키
// Vue.use(VueReCaptcha, {siteKey: '6LfVyCcaAAAAABwtZk4XFfSKP87BxgW1hbE0DyWK'}) //이원빈계정
// Vue.use(VueReCaptcha, {siteKey: '6LdyBEgaAAAAAIFwgNDCTchb7QAxqmYGjZODHqcG'}) //
// Vue.use(VueReCaptcha, {siteKey: '6LelxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhl'}) //테스트키
// 20230627 RECAPTCHA 예외
Vue.use(VueReCaptcha, {siteKey: '6LeTso8iAAAAAPM5aqXEuD9xqzkkdV7myb1CUTdG'}) //jaeyeob098@gmail.com 
// 20230627 RECAPTCHA 예외
Vue.use(LoadScript)
Vue.use(VueMask)
Vue.use(VueGoodTablePlugin)
// Vue.use(VueSheets, {theme: 'classic'})

// Vue.loadScript("https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit").then(
//   Vue.component('vue-recaptcha', VueReCaptcha))
// .catch((e) => {
//   console.log(`google api load failed: ${e.message}`)
// })

Vue.config.productionTip = false

Vue.mixin({
  components: {
  },
  data: function () {
    return {
    }
  },
  created: function () {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    moveMenu: function (toPath) {
      this.$router.push(toPath).catch(() => {})
    }
  },
  filters: {
    comma(val) {
      var num = new Number(val)
      return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    phone(val) {
      if(val != null && val != '' && val != ' - ' && val != '-') {
        var str = new String(val)
        if(str != null && str.length == 11) {
          str = str.substr(0,3)+'-'+str.substr(3,4)+'-'+str.substr(7,4) 
        } else {
          str = str.substr(0,4)+'-'+str.substr(4,4)+'-'+str.substr(8,4)
        }
        return str
      } else {
        return ''
      }
        // return str.replace(/[^0-9."*"]/g, "").replace(/(^02|^0505|^1[0-9."*"]{3}|^0[0-9."*"]{2})([0-9."*"]+)?([0-9."*"]{4})/,"$1-$2-$3").replace("--","-")
    },
    biznum(val) {
      if(val != null && val != '') {
        var str = new String(val)
        return str.substr(0,3)+'-'+str.substr(3,2)+'-'+str.substr(5,5)
      }
      // return str.replace(/([0-9]{3})([0-9]{2})([0-9]+)?/,"$1-$2-$3").replace("--","-")
    },
    cardnum(val) {
      if(val != null && val != '') {
        var str = new String(val)
        return str.substr(0,4)+'-'+str.substr(4,4)+'-'+str.substr(8,4)+'-'+str.substr(12,4)
      }
      // return str.replace(/([0-9]{4})([0-9]{4})([0-9]{4})([0-9]+)?/,"$1-$2-$3-$4").replace("--","-")
    },
    corpnonum(val) {
      if(val != null && val != '') {
        var str = new String(val)
        return str.substr(0,6)+'-'+str.substr(6,8)
      }
      // return str.replace(/([0-9]{6})([0-9]{7})?/,"$1-$2").replace("--","-")
    },
    replace0(val) {
      if(val != null && val != '') {
        var num = new Number(val)
        return num
      } else {
        return ''
      }
    },
    dateFormat1(val) {
      if(val != null && val != '') {
        var str = new String(val)
        return str.substr(0,4)+'/'+str.substr(4,2)+'/'+str.substr(6,2)
      }
    },
    date(val) {
      if(val != null && val != '') {
        var str = new String(val)
        const timeZone = 9 * 60 * 60 * 1000
        return new Date( + new Date(str) + timeZone).toISOString().replace('T', ' ').split('.')[0]
      }
    } 
  }
})

new Vue({
  axios,
  store,
  router,
  // recap_module,
  render: h => h(App),
}).$mount('#app')

