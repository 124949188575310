import { gigaofficeService } from 'Api'
import _ from 'lodash'

const state = {
  recommendedProduct: "",
  userInfo: "",
  reqList: "",
  reqDetail: "",
  orderPayload: "",
  savePdfInfo: "",
  taskHistory: [],
  accountListInfo: [],
  svcMasterInfo: '',
  userSvcInfo: [],
  closingAvailableStates: {},
  contractInfo:'',
  xmlJson:{}
}

const getters = {
  getRecommendedProduct: state => {
    return state.recommendedProduct;
  },
  getUserInfo: state => {
    return state.userInfo;
  }
}

const actions = {
  //마이비즈 > 가입상품관리 > 가입정보(goMyBiz100)
  //1.고객번호 조회

  //2.고객정보 조회 (콤보)
  // async getApplyOrderSubscriptionInfo(payload) {

  // },
  //3.청구정보 조회
  async getAccountListInfo(context, payload) {
    let response = await gigaofficeService.getAccountListInfo(payload)
    context.commit('GET_ACCOUNT_LIST_INFO', response.data)
    return state.accountListInfo
  },
  async getAuthContactInfo(context, payload) {
    let response = await gigaofficeService.getAuthContactInfo(payload)
    return response
  },
  async getCustAddressList(context, payload) {
    let response = await gigaofficeService.getCustAddressList(payload)
    return response
  },
  async getCustBldgList(context, payload) {
    let response = await gigaofficeService.getCustBldgList(payload)
    return response
  },
  async getComCodeList(context, payload) {
    let response = await gigaofficeService.getComCodeList(payload)
    return response
  },
  async getGoProductCostInfo(context, payload) {
    let response = await gigaofficeService.getGoProductCostInfo(payload)
    return response
  },
  async getGoProductInfo(context, payload) {
    let response = await gigaofficeService.getGoProductInfo(payload)
    return response
  },
  async getGoSubProductInfo(context, payload) {
    let response = await gigaofficeService.getGoSubProductInfo(payload)
    return response
  },
  async getCloudDirectCostInfo(context, payload) {
    let response = await gigaofficeService.getCloudDirectCostInfo(payload)
    return response
  },
  async getSvcMasterInfo(context, payload) {
    let response = await gigaofficeService.getSvcMasterInfo(payload)
    return response
  },
  async getSvcFlexlineMasterInfo(context, payload) {
    let response = await gigaofficeService.getSvcFlexlineMasterInfo(payload)
    return response
  },
  async retrieveChageTxnByBillTgtYm(context, payload) {
    let response = await gigaofficeService.retrieveChageTxnByBillTgtYm(payload)
    return response
  },
  async chageWebService(context, payload) {
    let response = await gigaofficeService.chageWebService(payload)
    return response
  },
  async chageBySvcWebService(context, payload) {
    let response = await gigaofficeService.chageBySvcWebService(payload)
    return response
  },
  async getNoticeInfo(context, payload) {
    let response = await gigaofficeService.getNoticeInfo(payload)
    return response
  },
  async getNoticeInfoDetail(context, payload) {
    let response = await gigaofficeService.getNoticeInfoDetail(payload)
    return response
  },
  async getGoSaOrderAply(context, payload) {
    let response = await gigaofficeService.getGoSaOrderAply(payload)
    return response
  },

  //마이비즈 > 가입상품관리 > 조회/변경 > 신청/변경 현황(goMyBiz101)
  //1.고객번호 조회
  async getUserInfo(context, payload) {
    let response = await gigaofficeService.getUserInfo(payload)
    return response
  },
  // 가입 신청
  async postGoOrderSubscriptionReceipt(context, payload) {
    let response = await gigaofficeService.postGoOrderSubscriptionReceipt(payload)
    return response
  },
  // 변경 예약 신청
  async postGoOrderSubscriptionReceiptReservation(context, payload) {
    let response = await gigaofficeService.postGoOrderSubscriptionReceiptReservation(payload)
    return response
  },
  // 변경 예약 신청 취소
  async postGoOrderSubscriptionReceiptReservationCancel(context, payload) {
    let response = await gigaofficeService.postGoOrderSubscriptionReceiptReservationCancel(payload)
    return response
  },
  //2.신청/변경 현황 조회
  async getSubscriptionListInfo(context, payload) {
    let response = await gigaofficeService.getSubscriptionListInfo(payload)
    context.commit('GET_SUBSCRIPTION_LIST_INFO', response.data)
    return state.reqList
  },
  //2-1.신청/변경 현황 조회
  async getOrderStatusList(context, payload) {
    let response = await gigaofficeService.getOrderStatusList(payload)
    return response
  },
  //3.가입 상세 조회
  async getSubscriptionListProductDetail(context, payload) {
    let response = await gigaofficeService.getSubscriptionListProductDetail(payload)
    context.commit('GET_SUBSCRIPTION_LIST_PRODUCT_DETAIL', response.data)
    return state.reqDetail
  },
  //4.고객회선 정보조회
  async getContractInfo(context, payload) {
    let response = await gigaofficeService.getContractInfo(payload)
    context.commit('GET_CONTRACT_INFO', response.data)
    return state.contractInfo
  },
  // 청약취소
  async cancelSubscription(context, payload) {
    let response = await gigaofficeService.cancelSubscription(payload)
    return response
  },
  // AS취소
  async getGoSaOrderCancel(context, payload) {
    let response = await gigaofficeService.getGoSaOrderCancel(payload)
    return response
  },
  // PDF저장(DB -> html에 출력 -> 인쇄기능)
  async savePdf(context, payload) {
    let response = await gigaofficeService.savePdf(payload)
    context.commit('SAVE_PDF', response.data)
    return state.savePdfInfo
  },

  //마이비즈 > 가입상품관리 > 조회/변경 > 설치장소 변경
  // 사업자/법인번호에 대한 고객의 전용번호/상품내역 조회
  async getUserSvcInfo(context, payload) {
    let response = await gigaofficeService.getUserSvcInfo(payload)
    context.commit('GET_USER_SVC_INFO', response.data)
    return state.userSvcInfo
  },
  // 트래픽현황
  async getTroubTicketInfo(context, payload) {
    let response = await gigaofficeService.getTroubTicketInfo(payload)
    return response
  },
  //성공사례 > 내게맞는상품찾기(goSusPod040)
  //1.추천상품조회
  async getRecommendedProduct(context, payload) {
    let response = await gigaofficeService.getRecommendedProduct(payload)
    context.commit('GET_RECOMMENDED_PRODUCT', response.data)
    return state.recommendedProduct
  },

  //자주하는 질문
  async getCustFaqInfo(context, payload) {
    let response = await gigaofficeService.getCustFaqInfo(payload)
    return response
  },

  // 
  async getFlexlineContractInfo(context, payload) {
    let response = await gigaofficeService.getFlexlineContractInfo(payload)
    return response
  },

  async getGoCenter(context, payload) {
    let response = await gigaofficeService.getGoCenter(payload)
    return response
  },

  async getServiceEquip(context, payload) {
    let response = await gigaofficeService.getServiceEquip(payload)
    return response
  },

  async getRentSwitch(context, payload) {
    let response = await gigaofficeService.getRentSwitch(payload)
    return response

  },

  async postVerifySend(context, payload) {
    let response = await gigaofficeService.postVerifySend(payload)
    return response

  },

  async postVerifyCheck(context, payload) {
    let response = await gigaofficeService.postVerifyCheck(payload)
    return response

  },

}

const mutations = {
  GET_USER_INFO(state, payload) {
    state.userInfo = payload.result
  },
  GET_ACCOUNT_LIST_INFO(state, payload) {
    // console.log(payload.result.length)
    // console.log(payload.result)
    state.accountListInfo = payload.result
  },
  GET_SUBSCRIPTION_LIST_INFO(state, payload) {
    state.reqList = payload.response
  },
  GET_SUBSCRIPTION_LIST_PRODUCT_DETAIL(state, payload) {
    state.reqDetail = payload.response
  },
  GET_CONTRACT_INFO(state, payload) {
    state.contractInfo = payload.result
  },
  CANCEL_SUBSCRIPTION(state, payload) {
  
  },
  SAVE_PDF(state, payload) {
    state.savePdfInfo = payload.response
  },
  GET_RECOMMENDED_PRODUCT(state, payload) {
    state.recommendedProduct = payload
  },
  GET_SVC_MASTER_INFO(state, payload) {
    state.svcMasterInfo = payload.result
  },
  GET_USER_SVC_INFO(state, payload) {
    state.userSvcInfo = payload.result
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}