// axios
import axios from 'axios'

/**
 * HTTP Post Request를 하기 위해 Axios를 사용하는 함수입니다.
 * @param {*} token   request를 받은 서버에서 Google reCAPTCHA API 호출 시에 사용되는 Token입니다.
 * @param {*} uri     score 반환 기능을 하는 RESTful API의 URI입니다. (별도 개발 필요)
 */

//axios.defaults.headers.post['Content-type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'Authorization, Origin, XRequested-With, Content-Type, Accept, User-Agent';// access-control-allow-headers, access-control-allow-methos, access-control-allow-origin, content-type, ip_addr, token_id, user_id
// axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'OPTIONS, GET, DELETE, POST, PUT, HEAD, PATCH';
axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'OPTIONS, POST, GET';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; 

export default axios
// .create({
//   baseURL: process.env.VUE_APP_API_URL
//   // You can add your headers here
//   // ,getScore
//   ,
//   beforeCreate() {
//     console.log(1111111112312312111)
//   }
// })
