<template>
  <div class="loadingBox">
    <div class="dimmed"></div>
    <div class="circle"></div>
    <!-- <div name="loading" v-if="loading"> -->
  </div>
</template>
<script>

export default {
  props: {
    // loading: {
    //   type: Boolean,
    //   required: true
    // }
  }
}
</script>
<style>
.loadingBox .dimmed {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  background: gray;
}
.loadingBox .circle {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1000000;
  transform: translate(-50%, -50%);
  width:40px;
  height: 40px;
  border: 10px solid #413A7A;
  border-top:10px solid #e9e9e9;
  border-radius:50em;
  animation-name: spinCircle;
  animation-duration: .8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spinCircle {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
/* .loadingBox {
  background: gray;
}
.loadingBox div{
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 41px;
  top: 47%;
  left: 47%;
  background:url('@/assets/images/m/images/common/logo_b_kt.png') ;
  opacity: 1;
  animation: opacity 2s linear infinite;
}
  @keyframes loading {
    0% {
      opacity: 90%;
      top: 6px;
      height: 51px;
    }
    50%, 100% {
      opacity: 10%;
      top: 19px;
      height: 25px;
    }
  } */

/* .loading div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #42b883;
  animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loading div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.loading div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.loading div:nth-child(3) {
  left: 45px;
  animation-delay: 0;
} */
@keyframes loading {
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 25px;
  }
}

</style>
