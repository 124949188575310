/**
 * Auth Module
 */
import Vue from 'vue'
import { authService } from 'Api'
import router from '@/router'
import store from "@/store/store";

// state
const state = {
    userInfo: {},
    ownerContactInfo: ''
}

// getters
const getters = {
    getUser: state => {
        return state.userInfo
    },
    getUserRoles: state => {
        return state.userInfo.roles || []
    },
    isAuthenticated: () => {
        //고객정보 없거나 토큰 없을떄
        if (!localStorage.getItem('userInfo') || !localStorage.getItem('accessToken'))
        {
            return false
        }
        
        return true
    },  
    //로그인 타임아웃
    checkTimeOut() {

    },
    getUserInfo: state => {
        return state.userInfo;
    },
    getOwnerContactInfoByTokenId: state => {
        return state.ownerContactInfo
    }
    
}

// actions
const actions = {
    //로그인
    async login(context, payload) {
        try {
            let response = await authService.login(payload)
            let result = response.data
            
            let returnCodeByLogin = result.code
            //authrize 성공
            if(returnCodeByLogin === '1') {
                let token_id = result.tokenId
                let aa = result.userId
                let param = {token_id: token_id, aa: aa}
                //토큰으로 인증하는 로직
                let resultToken = await authService.getUserInfoByToken(param)
                let returnCodeByToken = resultToken.data.returnCode
                // let tokenExpiry
                // returnCode
                // 0: Fail
                // 1: Success 
                // 2: Fail - Invalid Parameter
                // 3: Credential ID does
                // console.log("returnCodeByToken::"+returnCodeByToken)
                if(returnCodeByToken === '1') {
                    //개인:01, 조직:02
                    let partyTypeId = resultToken.data.result.partyTypeId
                    //사용자명
                    let userName = resultToken.data.result.partyName
                    //사용자ID
                    let userId = resultToken.data.result.userName
                    let userEmail = ''
                    let phoneNumber = ''
                    let mobileNumber = ''

                    if(resultToken.data.result.userEmail != null && resultToken.data.result.userEmail != 'undefined') {
                        userEmail = resultToken.data.result.userEmail
                    } else {
                        userEmail = ''
                    }
                    if(resultToken.data.result.phoneNumber != null && resultToken.data.result.phoneNumber != 'undefined') {
                        phoneNumber = resultToken.data.result.phoneNumber
                    } else {
                        phoneNumber = ''
                    }
                    if(resultToken.data.result.mobileNumber != null && resultToken.data.result.mobileNumber != 'undefined') {
                        mobileNumber = resultToken.data.result.mobileNumber
                    } else {
                        mobileNumber = ''
                    }
                    localStorage.setItem("userInfo", JSON.stringify(resultToken.data.result))
                    // context.commit('GET_TOKEN_INFO', resultToken.data)
                    localStorage.setItem('accessToken', result.tokenId)
                    // localStorage.setItem('expiration', result.tokenExpiry)
                    localStorage.setItem('partyTypeId', partyTypeId)
                    localStorage.setItem('userName', userName)
                    localStorage.setItem('userId', userId)
                    localStorage.setItem('userEmail', userEmail)
                    localStorage.setItem('phoneNumber', phoneNumber)
                    localStorage.setItem('mobileNumber', mobileNumber)
                    //set auth header
                    authService.setAuthorizationHeader(result.tokenId)
                    
                    store.commit('CLOSE_DIM')
                    store.commit('CLOSE_LOGIN_POP')
                    store.commit("END_SPINNER");
                } else {
                    store.commit("END_SPINNER");
                    alert('로그인에 실패하였습니다 고객 센터로 문의바랍니다.')
                }
            //returnCodeByLogin 실패코드인경우
            } else if (returnCodeByLogin == '99') {
                alert(result.message)
            } else {
                let maxValue = result.loginFailMaxValue
                let failCount = result.loginFailCounter
                let code = result.code
        
                if(code === '104' || returnCodeByLogin === '10' || returnCodeByLogin === '4') {
                    alert('아이디나 비밀번호가 정확하지 않습니다. \n다시 확인하고 로그인해주세요. \n비밀번호 5회 이상 오류 시 로그인이 제한되며, \n비밀번호 찾기 후 로그인이 가능합니다.')
                    return false
                }
                if(maxValue > failCount) {
                    alert('아이디 혹은 비밀번호를 '+failCount+'회 잘못 입력하셨습니다.\n 로그인 '+maxValue+'회 실패시 로그인이 제한됩니다.')
                    return false
                } else if (maxValue <= failCount) {
                    //비밀번호 확인 실패
                    window.open("https://login.kt.com/wamui/AthWebFail.do?domainNameCd=2&useOIF_200=Y", "_blank");
                    return false
                }
            }

            return state.resultToken
        }
        catch (error) {
            store.commit('CLOSE_DIM')
            store.commit('CLOSE_LOGIN_POP')
            store.commit("END_SPINNER");
            alert('로그인에 실패하였습니다. 고객 센터로 문의바랍니다.')
            throw error
        }
    },
    //로그아웃
    async logout(context, payload) {
        localStorage.removeItem("accessToken")
        localStorage.removeItem("expriation")
        localStorage.removeItem("partyTypeId")
        localStorage.removeItem("userName")
        localStorage.removeItem("userId")
        localStorage.removeItem("mobileNumber")
        localStorage.removeItem("phoneNumber")
        
        let response = await authService.logout(payload)
        sessionStorage.clear()
        router.replace('/goMain000')
        if(response.data.code === '100') {
            context.commit('LOGOUT', response.data)
            return response
        } else {
            return false
        }
    },
    //1.고객번호 조회
    async getUserInfo(context, payload) {
        let response = await authService.getUserInfo(payload)
        context.commit('GET_USER_INFO', response.data)
        return state.userInfo
    },

    async getOwnerContactInfoByTokenId(context, payload) {
        try {
            let response = await authService.getOwnerContactInfoByTokenId(payload)
            if(!response) {
                return false
            }
            context.commit('GET_OWNER_CONTACT_INFO_BY_TOKENID', response.data)
            return state.ownerContactInfo
        }
        catch (error) {
            throw error
        }
    },
    async getIpAddress() {
        try {
            let response = await authService.getIpAddress()
            return response
        }
        catch (error) {
            throw error
        }
    },
    async getAuthRsa(context, payload) {
        let response = await authService.getAuthRsa(payload)
        return response
    }
}

// mutations
const mutations = {
    LOGIN_SUCCESS(state, payload) {
        state.userInfo = payload
    },
    GET_TOKEN_INFO(state, payload) {
    },
    LOGIN_FAIL(state, payload) {
    },
    LOGOUT(state, payload) {
    },
    GET_CAPTCHA(state, payload) {
        state.captchaData = payload
    },
    GET_USER_INFO(state, payload) {
        state.userInfo = payload.response
    },
    GET_OWNER_CONTACT_INFO_BY_TOKENID(state, payload) {
        state.ownerContactInfo = payload.result
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}