/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'
import common from '../src/assets/js/common'
import date from 'date-and-time'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
    return window.location.reload()
  })
};
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },

  routes: [
    {
      path: '/gigaoffice/pageNotFound',
      name: 'pageNotFound',
      component: () => import('@/views/gigaoffice/common/pageNotFound.vue'),
      meta: {
        errorPage: true
      },
    },
    {
      path: '/',
      name: 'main/',
      component: () => import('@/views/gigaoffice/goMain000.vue'),
      meta: { authorityRequired: false }
    },
    {
      path: '/goLogin001',
      name: 'goLogin001',
      component: () => import('@/views/gigaoffice/goLogin001.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goMain000',
      name: 'main',
      component: () => import('@/views/gigaoffice/goMain000.vue'),
      meta: { authorityRequired: false }
    },
    {
      path: '/goMyBiz000',
      name: 'goMyBiz000',
      component: () => import('@/views/gigaoffice/goMyBiz000.vue'),
      meta: {
        authorityRequired: true
      },
    },
    {
      path: '/goMyFee000',
      name: 'goMyFee000',
      component: () => import('@/views/gigaoffice/goMyFee000.vue'),
      meta: {
        authorityRequired: true
      },
    },
    {
      path: '/goMySvc000',
      name: 'goMySvc000',
      component: () => import('@/views/gigaoffice/goMySvc000.vue'),
      meta: {
        authorityRequired: true
      },
    },
    {
      path: '/goSvcPod000',
      name: 'goSvcPod000',
      component: () => import('@/views/gigaoffice/goSvcPod000.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSvcPod100',
      name: 'goSvcPod100',
      component: () => import('@/views/gigaoffice/goSvcPod100.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSvcPod200',
      name: 'goSvcPod200',
      component: () => import('@/views/gigaoffice/goSvcPod200.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSvcPod201',
      name: 'goSvcPod201',
      component: () => import('@/views/gigaoffice/goSvcPod201.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSvcPod202',
      name: 'goSvcPod202',
      component: () => import('@/views/gigaoffice/goSvcPod202.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSvcPod300',
      name: 'goSvcPod300',
      component: () => import('@/views/gigaoffice/goSvcPod300.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSvcPod301',
      name: 'goSvcPod301',
      component: () => import('@/views/gigaoffice/goSvcPod301.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSvcPod400',
      name: 'goSvcPod400',
      component: () => import('@/views/gigaoffice/goSvcPod400.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSvcPod401',
      name: 'goSvcPod401',
      component: () => import('@/views/gigaoffice/goSvcPod401.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSvcOrd000',
      name: 'goSvcOrd000',
      component: () => import('@/views/gigaoffice/goSvcOrd000.vue'),
      meta: {
        authorityRequired: true
      },
    },
    {
      path: '/goSvcOrd001',
      name: 'goSvcOrd001',
      component: () => import('@/views/gigaoffice/goSvcOrd001.vue'),
      meta: {
        authorityRequired: true
      },
    },
    {
      path: '/goSvcOrd002',
      name: 'goSvcOrd002',
      component: () => import('@/views/gigaoffice/goSvcOrd002.vue'),
      meta: {
        authorityRequired: true
      },
    },
    {
      path: '/goSvcOrd003',
      name: 'goSvcOrd003',
      component: () => import('@/views/gigaoffice/goSvcOrd003.vue'),
      meta: {
        authorityRequired: true
      },
    },
    {
      path: '/goSvcAs000',
      name: 'goSvcAs000',
      component: () => import('@/views/gigaoffice/goSvcAs000.vue'),
      meta: {
        authorityRequired: true
      },
    },
    {
      path: '/goSvcSta000',
      name: 'goSvcSta000',
      component: () => import('@/views/gigaoffice/goSvcSta000.vue'),
      meta: {
        authorityRequired: true
      },
    },
    {
      path: '/goSupCns000',
      name: 'goSupCns000',
      component: () => import('@/views/gigaoffice/goSupCns000.vue'),
      meta: {
        authorityRequired: true
      },
    },
    {
      path: '/goSupPrd000',
      name: 'goSupPrd000',
      component: () => import('@/views/gigaoffice/goSupPrd000.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSupApl000',
      name: 'goSupApl000',
      component: () => import('@/views/gigaoffice/goSupApl000.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSupMov000',
      name: 'goSupMov000',
      component: () => import('@/views/gigaoffice/goSupMov000.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSupFaq000',
      name: 'goSupFaq000',
      component: () => import('@/views/gigaoffice/goSupFaq000.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSupNot000',
      name: 'goSupNot000',
      component: () => import('@/views/gigaoffice/goSupNot000.vue'),
      meta: {
        authorityRequired: false
      },
    },
    {
      path: '/goSupNot001',
      name: 'goSupNot001',
      component: () => import('@/views/gigaoffice/goSupNot001.vue'),
      meta: {
        authorityRequired: false
      },
    },
    // {
    //   path: '/goSvcPod201.vue',
    //   name: 'goSvcPod201',
    //   component: () => import('@/views/gigaoffice/goSvcPod201.vue'),
    //   meta: {
    //     authorityRequired: false
    //   },
    // },
    // {
    //   path: '/goSvcPod202.vue',
    //   name: 'goSvcPod202',
    //   component: () => import('@/views/gigaoffice/goSvcPod202.vue'),
    //   meta: {
    //     authorityRequired: false
    //   },
    // },
    // {
    //   path: '/goSvcPod300.vue',
    //   name: 'goSvcPod300',
    //   component: () => import('@/views/gigaoffice/goSvcPod300.vue'),
    //   meta: {
    //     authorityRequired: false
    //   },
    // },
    // {
    //   path: '/goSvcPod301.vue',
    //   name: 'goSvcPod301',
    //   component: () => import('@/views/gigaoffice/goSvcPod301.vue'),
    //   meta: {
    //     authorityRequired: false
    //   },
    // },
    // {
    //   path: '/goSvcPod400.vue',
    //   name: 'goSvcPod400',
    //   component: () => import('@/views/gigaoffice/goSvcPod400.vue'),
    //   meta: {
    //     authorityRequired: false
    //   },
    // },
    // {
    //   path: '/goSvcPod401.vue',
    //   name: 'goSvcPod401',
    //   component: () => import('@/views/gigaoffice/goSvcPod401.vue'),
    //   meta: {
    //     authorityRequired: false
    //   },
    // },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/gigaoffice/pageNotFound'
    }
  ],
})

router.beforeEach((to, from, next) => {
  //개인/기업 여부
  const partyTypeId = sessionStorage.getItem('partyTypeId')
  
  //화면 로그인필요 여부(true : 로그인 필요)
  const authorityRequired = to.matched.some(record => record.meta.authorityRequired)
  // console.log('현재시간:'+date.format(new Date(), 'YYYYMMDDHHmmss'))
  // console.log('만료시간:'+sessionStorage.getItem('expirationTime'))

  //토큰
  const accessToken = sessionStorage.getItem('accessToken')
  // if (to.fullPath === '/gigaoffice/goSvcPod400') {
  if (accessToken && sessionStorage.getItem('expirationTime') < date.format(new Date(), 'YYYY-MM-DD HH:mm:ss')) {
    alert('로그인 세션이 만료되었습니다.')
    let param = {}
    store.dispatch("auth/logout", param).then((res) => {
      if(res.data.code == 100) {
        router.replace('/')
        setTimeout(() => {
          common.loginPop()
        }, 500);
      } else {
        alert(res.data.message)
      }
    })
    .catch(error => {
      console.error(error);
    });
    //로그아웃
    return false
  } else if(accessToken){
    sessionStorage.setItem('expirationTime', date.format(date.addHours(new Date(), 2), 'YYYY-MM-DD HH:mm:ss'))
  }

  if(authorityRequired) {
    if(accessToken && partyTypeId === '02') {
      next()
    } else if(accessToken && partyTypeId === '01') {
      alert('법인고객만 사용가능한 서비스 입니다.')
    } else {
      common.loginPop()
      return false
   }
  } else {
    next()
  }
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

export default router
