import store from "@/store/store";

const common = {
    /**
     * 
     * @param {*} url url
     * @param {*} title 팝업창 타이틀
     * @param {*} w 가로길이
     * @param {*} h 세로길이
     * @returns 
     */
    openLoginPop(url, title, w, h) {
        let y = window.top.outerHeight / 2 + window.top.screenY - (h / 2)
        let x = window.top.outerWidth / 2 + window.top.screenX - (w / 2)
        return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, copyhistory=no, width='+w+', height='+h+', top='+y+', left='+x)
    },
    confirmPop(msg) {
        if(msg == null || msg == '') {
            console.warn('confirm message is empty');
            return;
        } else {
            
        }
    },
    alertPop(msg) {

    },
    openPop() {

    },
    closePop() {

    },
    getOffset(el) {
      var _x = 0;
      var _y = 0;
      while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
      }
      return { top: _y, left: _x };
    },
    async loginPop() {
        store.commit('OPEN_DIM')
        store.commit('OPEN_LOGIN_POP')
    },
}  
export default common

