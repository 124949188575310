import HttpRequest from './base/http_request'
// import router from '@/router'
class GigaofficeService extends HttpRequest {
  //마이비즈 > 가입상품관리 > 가입정보(goMyBiz100)
  //1.고객번호 조회
 
  //2.고객정보 조회
  // getApplyOrderSubscriptionInfo(payload) {
  //   return this.post(`${process.env.VUE_APP_API_ALIAS}/mybiz/apply-order-subscription-info`, payload)
  // } 
  //3.청구정보 조회
  getAccountListInfo(payload1) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/account-list-info`, payload1)
  } 
  


  //마이비즈 > 가입상품관리 > 조회/변경 > 신청/변경 현황(goMyBiz101)
  //1.
  getUserInfo(payload2) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/user-info`, payload2)
  }
  //2.신청/변경 현황 조회
  getSubscriptionListInfo(payload3) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/mybiz/subscription-list-info`, payload3)
  }
  getOrderStatusList(payload3) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/order-status-list`, payload3)
  }
  //3.가입 상세 조회
  getSubscriptionListProductDetail(payload4) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/mybiz/subscription-list-product-detail`, payload4)
  }
  //4.고객회선 정보조회
  getContractInfo(payload5) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/go/contract-info`, payload5)
  }
  //5.청약취소(미정의)
  cancelSubscription(payload6) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/go/order-subscription-cancel`, payload6)
  }
  //6.PDF저장
  savePdf(payload7) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/mybiz/subscription-info-pdfsave`, payload7)
  }

  
  //요금기본정보상단
  retrieveChageTxnByBillTgtYm(payload8) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/chageInfoAdm_retrieveChageTxnByBillTgtYm`, payload8)
  }
  
  //요금기본정보하단_청구상품별
  chageWebService(payload8) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/invocExternService_ChageWebService`, payload8)
  }
  
  //요금기본정보하단_서비스번호별
  chageBySvcWebService(payload8) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/invocExternService_ChageBySvcWebService`, payload8)
  }

  //사용자 서비스 가입 정보 조회 
  getUserSvcInfo(payload8) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/cust-svc-info`, payload8)
  }

  getRecommendedProduct(payload9) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/success/recommended-products`, payload9)
  }
  
  getGoProductCostInfo(payload10) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/go/product-cost-info`, payload10); //
  }
  getGoSubProductInfo(payload11) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/go/sub-product-info`, payload11); //
  }
  getGoProductInfo(payload12) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/go/product-info`, payload12); //
  }
  getCloudDirectCostInfo(payload12) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/go/cloud-sub-product-cost-info`, payload12); //
  }

  // SUPPORT > 서비스 상담 > 상담 신청
  // 1. 상담 신청
  postConsult(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/online-consult-info`, payload); //
  }
  getCustAccountListInfo(payload14) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/account-list-info`, payload14); //
  }

  // 가입 신청
  postGoOrderSubscriptionReceipt(payload15) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/go/flexline-order-subscription-receipt`, payload15); //
  }

  // 변경 예약 신청
  postGoOrderSubscriptionReceiptReservation(payload15) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/go/flexline-order-subscription-receipt/reservation`, payload15); //
  }
  
  // 변경 예약 신청 취소
  postGoOrderSubscriptionReceiptReservationCancel(payload15) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/go/flexline-order-subscription-receipt/reservation/cancel`, payload15); //
  }

  getCustAddressList(payload16) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/address-list`, payload16); //
  }
  getCustBldgList(payload17) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/bldg-list`, payload17); //
  }
    
  // 트래픽현황
  getTroubTicketInfo(payload12) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/troubticketinfo`, payload12); //
  }

  // 고객지원 / 공지사항
  getNoticeInfo(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/notice-info`, payload); //
  }
  // 고객지원 / 공지사항상세
  getNoticeInfoDetail(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/notice-info-detail`, payload); //
  }
  // 자주하는 질문
  getCustFaqInfo(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/faq-info`, payload); //
  }
  // AS 신청 및 변경 #1
  getSvcMasterInfo(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/svc-master-info`, payload); //
  }
  getSvcFlexlineMasterInfo(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/svc-flexline-master-info`, payload); //
  }
  // AS 신청 및 변경 #2
  getGoSaOrderAply(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/go-sa-order-aply`, payload); //
  }
  // AS 신청 및 변경 #3
  getGoSaOrderProcessStatus(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/go-sa-order-process-status`, payload); //
  }
  // AS 신청 및 변경 #4
  getGoSaOrderCancel(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/cust/go-sa-order-cancel`, payload); //
  }
  // Common Code list
  getComCodeList(payload) {
    // console.log(router.currentRoute.fullPath)
    return this.post(`${process.env.VUE_APP_API_ALIAS}/com/code-list`, payload); //
  }
  // getAuth Contact-info
  getAuthContactInfo(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/auth/contact-info`, payload); //
  } 

  getFlexlineContractInfo(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/go/flexline-contract-info`, payload); //
  } 

  getGoCenter(payload) {
    return this.get(`${process.env.VUE_APP_API_ALIAS}/center?address=` + payload); //
  }

  getServiceEquip(payload) {
    return this.get(`${process.env.VUE_APP_API_ALIAS}/service/equip?svcTypeCd=` + payload.svcTypeCd + '&lineSpeed=' + payload.lineSpeed);
  }

  getRentSwitch(payload) {
    return this.get(`${process.env.VUE_APP_API_ALIAS}/service/rent/switch?svcTypeCd=` + payload.svcTypeCd + '&termCntrctTypeCd=' + payload.termCntrctTypeCd);
  }

  postVerifySend(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/verify_send`, payload);
  }

  postVerifyCheck(payload) {
    return this.post(`${process.env.VUE_APP_API_ALIAS}/verify_check`, payload);
  }

}

export default GigaofficeService